.page-bg {
  position: fixed;
  inset: 0;
  margin: auto;

  @media (max-width: 991.98px) and (orientation: portrait) {
    max-height: 80vh;
  }

  &:after,
  &:before {
    position: absolute;
    content: '';
    z-index: 1;
  }

  &:after {
    height: 8dvh;
    inset: 0 0 auto;
    background: linear-gradient(#101011, transparent);
  }

  &:before {
    height: 8dvh;
    inset: auto 0 0;
    background: linear-gradient(transparent, #101011);
  }
}
