.lasers {
  position: fixed;
  inset: 0;
  margin: auto;
  z-index: var(--z-index-lasers);
  width: 100vw;
  height: 100dvh;
  will-change: filter;
  filter:
    drop-shadow(0 0 5.5px rgba(255, 0, 0, var(--laser-shadow-opacity, 0.85)))
    drop-shadow(0 0 3.5px rgba(255, 0, 0, var(--laser-shadow-opacity, 0.85)))
    drop-shadow(0 0 1.5px rgba(255, 0, 0, var(--laser-shadow-opacity, 0.85)));

  @include min(tablet-portrait) {
    filter:
      drop-shadow(0 0 3.5px rgba(255, 0, 0, var(--laser-shadow-opacity, 0.85)))
      drop-shadow(0 0 2.5px rgba(255, 0, 0, var(--laser-shadow-opacity, 0.85)))
      drop-shadow(0 0 1.5px rgba(255, 0, 0, var(--laser-shadow-opacity, 0.45)))
      drop-shadow(0 0 1.5px rgba(255, 0, 0, 1));
  }

  &__beam-one {
    stroke: rgba(255, 180, 152, 0.45);
    fill: rgba(255, 246, 206, 0.65);
  }

  &__beam-two {
    stroke: rgba(255, 255, 136, 0.15);
    fill: rgba(255, 254, 71, 0.65);
  }

  &__beam-three {
    stroke: rgba(255, 180, 152, 0.45);
    fill: rgba(255, 246, 206, 0.65);
  }

  &__beam-four {
    stroke: rgba(255, 180, 152, 0.45);
    fill: rgba(255, 246, 206, 0.65);
  }

  &__beam-five {
    stroke: rgba(255, 255, 136, 0.15);
    fill: rgba(255, 254, 71, 0.65);
  }
}
