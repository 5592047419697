html,
body {
  position: relative;
}

body {
  overflow: hidden auto;
  display: grid;
  width: 100vw;
  min-height: 100dvh;
  background-color: var(--c-bg-page);
}

.page {
  position: relative;
  z-index: var(--z-index-page);
  display: grid;
  grid-template: auto auto / 1fr;
  gap: 5vh 0;

  &__logotype {
    justify-self: center;
  }

  &__safes {
    align-self: flex-end;
    padding: 20dvh 0 4dvh;
  }
}
