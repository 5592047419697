.title-large {
  margin: 0 0 calc(var(--v-offset-sm) / 2);
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.7em;
  max-inline-size: none;

  @include min(tablet-portrait) {
    font-size: 2em;
    line-height: 1.3em;
  }
}

.title-medium {
  margin: 0 0 calc(var(--v-offset-sm) / 2);
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.7em;
  max-inline-size: none;

  @include min(tablet-portrait) {
    font-size: 1.6em;
    line-height: 1.5em;
  }
}
