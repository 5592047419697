.btn {
  display: grid;
  place-content: center;
  width: fit-content;
  height: 48px;
  margin: auto;
  padding: 0 1.5em;
  background:
    linear-gradient(135deg, transparent 8px, #FF0305 0),
    linear-gradient(-45deg, transparent 8px, #FF0305 0);
  background-size: 100% 50%, 100% 50%;
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  font-size: 1.8em;
  text-transform: uppercase;
  line-height: 0;
  cursor: pointer;
  color: White;
  text-decoration: none;

  @include min(tablet-portrait) {
    height: 56px;
    font-size: 2.4em;
  }
}
