.promo-code {
  --border: var(--c-modal-frame-yellow);
  --text: White;

  position: relative;
  width: fit-content;
  margin: 30px auto 20px;
  padding: 1.8em 1.6em 1.8em 2.2em;
  display: flex;
  align-items: center;
  border: 2px dotted var(--border);
  transition: border 225ms ease-in-out, color 225ms ease-in-out;
  cursor: pointer;
  color: var(--text);
  border-radius: 2px;
  -webkit-tap-highlight-color: transparent;

  &::before {
    content: 'Скопировано';
    position: absolute;
    inset: calc((1em + 10px) * -1) auto auto 0;
    width: fit-content;
    height: fit-content;
    margin: auto;
    padding: 5px 0;
    border-radius: 1.5px;
    font-size: 10px;
    line-height: 1em;
    text-transform: uppercase;
    color: White;
    opacity: 0;
    transition: opacity 225ms ease-in-out, background 225ms ease-in-out;
  }

  &.copied {
    --border: var(--c-modal-frame-orange);
    --text: var(--c-modal-frame-orange);

    &::before {
      opacity: 1;
    }
  }

  &__text {
    line-height: 0;
    font-size: 2.6em;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-left: 1.6em;
    fill: var(--text);
    transition: fill 225ms ease-in-out;
  }
}
