@import "base";
@import "cssbin";

.test {
  color: var(--c-modal-frame-yellow);
  font-size: 17px;

  @media (width >= 992px) {
    font-size: 2em;
  }
}
