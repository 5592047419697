.safes-game {
  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  margin: auto;
  width: min(1020px, calc(100vw - 40px));

  @include max(tablet-portrait) {
    padding-bottom: 15dvh;
  }

  &__item {
    will-change: transform;
    aspect-ratio: 4 / 4.2;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    filter: drop-shadow(0 2vmax 2vmax rgba(0, 0, 0, 1));
    cursor: pointer;

    &.loose {
      background-position: 50% 0;
      filter:
        grayscale(80%)
        drop-shadow(0 1vmax 1vmax rgba(0, 0, 0, 0.85));
    }

    &.win {
      background-position: 100% 0;
      filter:
        drop-shadow(0 -2vmax 5vmax hsla(var(--shadow-color), 50%, 50%, 1))
        drop-shadow(0 1vmax 1vmax rgba(0, 0, 0, 1));
    }
  }
}
