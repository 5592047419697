$color-particle: White;
$spacing: 2560px;
$time-one: 60s;
$time-two: 120s;
$time-three: 180s;
$time-four: 600s;

@function particles($max) {
  $val: 0 0 $color-particle;

  @for $i from 1 through $max {
    $val: #{$val},
    random($spacing) random($spacing) $color-particle;
  }

  @return $val;
}

@mixin particles($max) {
  box-shadow: particles($max);
}

.particles {
  position: fixed;
  inset: 0;
  z-index: var(--z-index-particles);
  margin: auto;
  opacity: 0.4;
  pointer-events: none;

  &::after {
    position: absolute;
    content: '';
    top: $spacing;
  }

  &__one {
    animation: particles-animation $time-one linear infinite;
    @include particles(600);
    height: 1px;
    width: 1px;

    &::after {
      @include particles(600);
      height: 1px;
      width: 1px;
    }
  }

  &__two {
    animation: particles-animation $time-two linear infinite;
    @include particles(200);
    height: 2px;
    width: 2px;

    &::after {
      @include particles(200);
      height: 2px;
      width: 2px;
    }
  }

  &__three {
    animation: particles-animation $time-three linear infinite;
    @include particles(100);
    height: 3px;
    width: 3px;

    &::after {
      @include particles(100);
      height: 3px;
      width: 3px;
    }
  }

  &__four {
    animation: particles-animation $time-four linear infinite;
    @include particles(400);
    height: 1px;
    width: 1px;

    &::after {
      @include particles(400);
      height: 1px;
      width: 1px;
    }
  }
}

@keyframes particles-animation {
  to {
    transform: translateY($spacing * -1);
  }
}
