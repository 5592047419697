.message-frame {
  --c-message-frame-text: White;
  --c-message-frame-bg: rgba(0, 0, 0, 0.7);
  --c-message-frame-border-one: var(--c-modal-frame-black);

  .modal--one & {
    --c-message-frame-border-two: var(--c-modal-frame-orange);
  }

  .modal--two &,
  .modal--three & {
    --c-message-frame-border-two: var(--c-modal-frame-yellow);
  }

  margin: auto;
  width: clamp(274px, 92vw, 580px);
  height: fit-content;

  &__body {
    position: relative;
    margin: 45px 24px;
    padding: var(--v-offset-sm) 30px;
    background-color: var(--c-message-frame-bg);
    color: var(--c-message-frame-text);

    &:after {
      position: absolute;
      inset: 0 auto 0 10px;
      margin: auto;
      content: '';
      width: 8px;
      height: 100%;
      background: repeating-linear-gradient(
        45deg,
        var(--c-message-frame-border-two) 0px,
        var(--c-message-frame-border-two) 10px,
        transparent 10px,
        transparent 20px
      );
    }

    &:before {
      position: absolute;
      inset: 0 10px 0 auto;
      margin: auto;
      content: '';
      width: 8px;
      height: 100%;
      background: repeating-linear-gradient(
        45deg,
        var(--c-message-frame-border-two) 0px,
        var(--c-message-frame-border-two) 10px,
        transparent 10px,
        transparent 20px
      );
    }

    .btn {
      margin-top: 1.1em;
    }
  }

  &__border {
    pointer-events: none;
    position: absolute;
    inset: 0;
    margin: auto;
    display: grid;
    grid-template: 111px auto 111px / 136px auto 136px;
    gap: 0;
    height: 100%;

    .svg-icon {
      position: relative;
      fill: var(--c-message-frame-border-two);
      color: var(--c-message-frame-border-one);

      &:nth-child(1) {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        width: 136px;
        height: 111px;
      }

      &:nth-child(2) {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        width: 100%;
        height: 111px;
      }

      &:nth-child(3) {
        grid-row: 1 / 2;
        grid-column: 3 / 4;
        width: 136px;
        height: 111px;
      }

      &:nth-child(4) {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        width: 136px;
        height: calc(100% + 1px);
      }

      &:nth-child(5) {
        grid-row: 2 / 3;
        grid-column: 3 / 4;
        width: 136px;
        height: calc(100% + 1px);
      }

      &:nth-child(6) {
        grid-row: 3 / 4;
        grid-column: 1 / 2;
        width: 136px;
        height: 111px;
      }

      &:nth-child(7) {
        grid-row: 3 / 4;
        grid-column: 2 / 3;
        width: 100%;
        height: 111px;
      }

      &:nth-child(8) {
        grid-row: 3 / 4;
        grid-column: 3 / 4;
        width: 136px;
        height: 111px;
      }
    }
  }
}
